import {
  UilMobileAndroid,
  UilDesktop,
  UilTablet
} from '@iconscout/react-unicons';
import dropdownEnums, {
  Enums,
  TimeIntervalsEnum
} from 'services/dropdown-enums';
import storage from 'services/utils/storage';
export const GraphColors = {
  Unknown: '#00C709',
  Bar: '#FF9B6A',
  Static: '#FF333C',
  Standard: '#8E05C2',
  Tab: '#00D0AA',
  ExitPopup: '#B83C7A',
  SmsStatic: '#FF7E00',
  FacebookMessenger: '#CF00FF',
  Facebook: '#CF00FF',
  PreChat: '#ffd82f',
  SmsWeb: 'brown',
  WebApi: '#335BFF',
  Paid: '#335BFF',
  Organic: '#CF00FF',
  Sms: '#FF7E00',
  Socio: '#CF00FF',
  ChatGraph: '#00D0AA',
  LeadGraph: '#B83C7A',
  Operator: '#30CA00',
  Agent: '#335bff',
  Activated: '#00D0AA',
  GoogleMyBusiness: '#ce7e00'
};

export const Features = Object.freeze(
  dropdownEnums.enum_InitiatedBy
    .map(ib => ({
      key: ib.name,
      value: false,
      id: ib.value,
      name: ib.key,
      selectorType: ib.selectorType
    }))
    .filter(v => v.id !== Enums.InitiatedBy['OptIn'])
);
export const FeaturesOptionSelector = Object.freeze([
  { key: 'web', label: 'Web', checked: false },
  { key: 'sms', label: 'SMS', checked: false },
  { key: 'social', label: 'Social', checked: false }
]);
export const SelectionTypeKeys = ['devices', 'features', 'hours', 'userTypes'];
export const InputFieldTypeKeys = ['items', 'businessTypes'];

const icon = {
  Desktop: <UilDesktop />,
  Mobile: <UilMobileAndroid />,
  Tablet: <UilTablet />,
  Android: <UilMobileAndroid />,
  IOS: <UilMobileAndroid />
};

export const initialSelectedFilters = {
  businessTypes: [],
  devices: [],
  features: [],
  hours: [],
  items: [],
  userTypes: []
};

export const Devices = Object.freeze(
  dropdownEnums.enum_Devices.map(ib => ({
    key: ib.name,
    value: false,
    icon: icon[ib.name],
    id: ib.value
  }))
);

export const initializeVissibleCards = (arg = true, data = Enums.graphType) => {
  let initials = {};
  Object.keys(data).forEach(key => {
    initials[key] = arg;
  });
  return initials;
};

export const initialGraphsCharts = {
  ChatVsLeadGraph: {
    ChatGraph: [],
    LeadGraph: []
  },
  FeatureUsage: {},
  ChatReferral: {},
  ChatStats: { Agent: [], Operator: [] },
  AccountsActivations: [],
  TopInActiveBillersGraph: [],
  TopBottomBillers: { Top: [], Bottom: [] },
  AgentsImagesCTR: [],
  AccountsAddedCancelled: { Added: [], Cancelled: [] },
  BudgetPacings: []
};
export const initialVoiceGraphsCharts = {
  OutboundCallsGraph: {
    Abandoned: [],
    Answered: []
  },
  RetainersOverviewGraph:{
    Offered:[],
    Signed:[]
  }
};
export const initialGraphsLoading = () => {
  let lodaingData = {};
  Object.keys(Enums.graphType).forEach(key => {
    lodaingData[key] = false;
  });
  return lodaingData;
};

export const UserTypes = Object.freeze(
  dropdownEnums.enum_UserType
    .filter(f => ![1, 4].includes(f.value))
    .map(i => ({
      id: i.value,
      key: i.key,
      name: i.name.replace(/Bot Assistant/g, 'Bot'),
      value: false
    }))
);

export const Hours = Object.freeze([
  {
    key: 'During business hours',
    value: false
  },
  {
    key: 'After business hours',
    value: false
  }
]);

export const initialSelected = {
  businessTypes: [],
  devices: [],
  features: [],
  hours: [],
  items: [],
  userTypes: []
};
export const initializeDuration = (duration = '', storageKey = 'duration') => {
  let storedDuration = JSON.parse(storage.getItem(storageKey));
  if (storedDuration) {
    storedDuration.startDate = new Date(storedDuration.startDate);
    storedDuration.endDate = new Date(storedDuration.endDate);
    return storedDuration;
  }
  return duration || null;
};

export const Company = Object.freeze([]);

export const quickStatsOptions = Object.freeze([
  { id: 1, key: 'Total Visits', value: false },
  { id: 2, key: 'Total Chats', value: false },
  { id: 3, key: 'Total Leads', value: false },
  { id: 4, key: 'Total Billable Leads', value: false },
  { id: 5, key: 'Click Through Rate', value: false },
  { id: 6, key: 'Lead Conversion Rate', value: false },
  { id: 7, key: 'Blank Chats', value: false },
  { id: 8, key: 'Active Call Connect', value: false },
  { id: 9, key: 'Call Connect Attempts', value: false },
  { id: 10, key: 'Call Connect Duration', value: false },
  { id: 11, key: 'Active Chats', value: false },
  { id: 12, key: 'Signed Retainers', value: false }
]);

export const generateQuickStatsOptions = docusignEnabled => {
  const baseOptions = [
    { id: 1, key: 'Total Visits', value: false },
    { id: 2, key: 'Total Chats', value: false },
    { id: 3, key: 'Total Leads', value: false },
    { id: 4, key: 'Total Billable Leads', value: false },
    { id: 5, key: 'Click Through Rate', value: false },
    { id: 6, key: 'Lead Conversion Rate', value: false },
    { id: 7, key: 'Blank Chats', value: false },
    { id: 8, key: 'Active Call Connect', value: false },
    { id: 9, key: 'Call Connect Attempts', value: false },
    { id: 10, key: 'Call Connect Duration', value: false },
    { id: 11, key: 'Active Chats', value: false }
  ];

  if (docusignEnabled) {
    baseOptions.push({ id: 12, key: 'Signed Retainers', value: false });
  }
  return baseOptions;
};

export const voiceQuickStatsOptions = docusignEnabled => {
  const baseOptions = [
    { id: 5, key: 'RetainerSigned', value: false },
    { id: 6, key: 'InboundCalls', value: false },
    { id: 7, key: 'OutboundCalls', value: false },
    { id: 8, key: 'AvgTalkTime', value: false },
    { id: 11, key: 'AvgRingTime', value: false }
  ];
  return baseOptions;
};

export const liveQuickStats = Object.freeze([
  { id: 8, key: 'Active Call Connect', value: false },
  { id: 11, key: 'Active Chats', value: false }
]);

export const DurationOptions = {
  'Last 24 Hours': TimeIntervalsEnum.Day,
  'Last 30 Days': TimeIntervalsEnum.Month,
  'Last 6 Months': TimeIntervalsEnum.SixMonths
};

export const GetDurationByEnum = val => {
  let key = Object.keys(DurationOptions).filter(
    key => DurationOptions[key] === Number(val)
  );
  if (key.length) return key[0];
  else return '';
};

export const withOutFiltersCards = [
  Enums.graphType['Chat Referrals'],
  Enums.graphType['Accounts Activation'],
  Enums.graphType['Top Inactive Billers'],
  Enums.graphType['Budget/Pacing'],
  Enums.graphType['Agent Images CTR'],
  Enums.graphType['Accounts']
];
export const initialChatStatsButtons = [
  { id: 'OperatorsEnabled', label: 'Operator', key: 'Operator' },
  { id: 'AssignToAgents', label: 'Agent', key: 'Agent' }
];
