import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { voiceDashboardAPI } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const voicesSlice = createSlice({
  name: 'voice',
  initialState: {
    voice: [],
    selectedCompany: null
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    // setCompaniesToState: (state, action) => {
    //   state.companies = [action.payload.data];
    // } 

  }
});

export const getAllCallRecording = payload => {
  let voiceDashboardAPI1 = voiceDashboardAPI.getAllCallRecords;
  return ApiServices.getWithParams(voiceDashboardAPI1, { params: payload });
};

export const getVoiceQuickStats = payload => {
  let analyticAPI = voiceDashboardAPI.getVoiceQuickStats;
  return ApiServices.post(analyticAPI, payload);
};

export const getChartsAndGraphs = payload => {
  let analyticAPI = voiceDashboardAPI.getChartsAndGraphs;
  return ApiServices.post(analyticAPI, payload);
};


export default voicesSlice.reducer;
